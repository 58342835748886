<template>
  <div class="list-menu-box">
    <div class="list-menu-main">
        <div class="list-menu-main-left">
            <img :src="menuTitleData.mainLeftIcon" :alt="menuTitleData.mainTitle||'常见问题'" class="list-menu-main-left-icon">
            <p class="list-menu-main-title">{{menuTitleData.mainTitle||'常见问题'}}</p>
        </div>
        <img :src="menuTitleData.mainRightIcon" :alt="menuTitleData.mainTitle||'常见问题'" class="list-menu-main-right-icon">
    </div>
    <div class="list-menu-sub">
        <ul>
            <li v-for="item in menuTitleData.subItemData" :key="item.id" @click="clickSubMenuHandler(item.id)" :style="{background:currentMenuIndex===item.id?'linear-gradient( 270deg, #FFFFFF 0%, #F5F5F5 100%)':''}" >
                <div class="list-menu-sub-slider" :style="{display:currentMenuIndex===item.id?'block':''}"></div>
                <p class="list-menu-sub-title" :style="{color:currentMenuIndex===item.id?'#F02233':''}">{{item.subTitle}}</p>
            </li>
        </ul>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
    name:'ListMenu',
    props:{
        mianTitleIndex:{
            type:Number,
            default:0
        },
        menuTitleData:{
            type:[Array,Object],
            required:true,
            default:()=>[]
        },
    },
    data(){
        return{
            
        }
    },
    methods:{
        //点击菜单操作
        clickSubMenuHandler(subTitleIndex){
            // this.currentIndex = index;
            this.$store.commit('helpCenter/setCurrentMenuIndex',subTitleIndex);
            this.$emit('currentClickMenuIndex',this.mianTitleIndex,subTitleIndex);
        }
    },
    computed:{
        ...mapState({
            currentMenuIndex:state=>state.helpCenter.currentMenuIndex
        })
    }
}
</script>

<style lang="less" scoped>
.list-menu-box{
    width: 280px;
    .list-menu-main{
        width: 280px;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .list-menu-main-left{
            margin-left: 20px;
            height: 21px;
            display: flex;
            align-items: center;
            .list-menu-main-left-icon{
                width: 16px;
                height: 16px;
            }
            .list-menu-main-title{
                margin-left: 10px;
                height: 21px;
                font-family: MicrosoftYaHei, MicrosoftYaHei;
                font-weight: bold;
                font-size: 16px;
                color: #222222;
                line-height: 21px;
                text-align: left;
                font-style: normal;
                cursor: default;
            }
        }
        .list-menu-main-right-icon{
            margin-right: 17px;
            width: 12px;
            height: 7px;
        }
    }
    .list-menu-sub{
        ul{
            // background-color: pink;
            margin-top: 12px;
            list-style-type: none;
            li{
                margin-bottom: 3px;
                list-style: none;
                display: flex;
                align-items: center;
                cursor: pointer;
                position: relative;
                .list-menu-sub-slider{
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 5px;
                    height: 36px;
                    background: #F02233;
                    display: none;
                }
                .list-menu-sub-title{
                    margin-left: 46px;
                    height: 36px;
                    font-family: MicrosoftYaHei;
                    font-size: 14px;
                    color: #424242;
                    line-height: 36px;
                    text-align: left;
                    font-style: normal;
                }
                &:hover .list-menu-sub-slider{
                    display: block;
                }
                &:hover {
                    background: linear-gradient( 270deg, #FFFFFF 0%, #F5F5F5 100%);
                }
                &:hover .list-menu-sub-title{
                    color: #F02233;
                }
            }
        }
    }
}
            
</style>