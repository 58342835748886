<template>
  <div class="list-content">
    <div class="list-content-top">
        <p class="list-content-top-main-title">{{mainTitle||'常见问题'}}</p>
        <p class="list-content-top-sub-title">{{contentData.subTitle}}</p>
    </div>
    <div class="list-content-bottom">
        <img :src="contentData.imgSrc" :alt="contentData.subTitle||'常见问题'" class="list-content-bottom-pic">
    </div>
  </div>
</template>

<script>
export default {
    name:'FAQListContent',
    props:{
        mainTitle:{
            type:String,
            default:''
        },
        contentData:{
            type:Object,
            default:()=>({
                mainTitle:'常见问题',
                subTitle:'如何购买？',
                imgSrc:'https://newgamemarket.oss-cn-beijing.aliyuncs.com/tbh_pc_images/how_to_buy_steps.png'
            })
        }
    },
    methods:{
        
    }
}
</script>

<style lang="less" scoped>
.list-content{
    width: 860px;
    .list-content-top{
        width: 860px;
        height: 92px;
        border-bottom: 1px solid #F0F0F0;
        .list-content-top-main-title{
            height: 19px;
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #666666;
            line-height: 19px;
            text-align: left;
            font-style: normal;
        }
        .list-content-top-sub-title{
            margin-top: 20px;
            height: 32px;
            font-family: MicrosoftYaHei, MicrosoftYaHei;
            font-weight: bold;
            font-size: 24px;
            color: #222222;
            line-height: 32px;
            text-align: left;
            font-style: normal;
        }
    }
    .list-content-bottom{
        width: 860px;
        .list-content-bottom-pic{
            width: 860px;
            margin-top: 20px;
        }
    }
}
</style>