<template>
    <div class="container">
         <div class="content">
           <div class="content-left">
              <!-- 常见问题 -->
              <list-menu v-for="(item,index) in menuTitleAndContentData" :key="index" :mianTitleIndex="index" :menuTitleData="item" @currentClickMenuIndex="currentClickMenuIndex"></list-menu>
              <!-- QQ账号须知 -->
              <!-- <list-menu :menuTitleData="qqAccountMenuTitleData" @currentClickMenuIndex="currentClickMenuIndex"></list-menu> -->
              <!-- 微信账号须知 -->
              <!-- <list-menu :menuTitleData="wxAccountMenuTitleData" @currentClickMenuIndex="currentClickMenuIndex"></list-menu> -->

           </div>
           <div class="content-right">
             <list-content :mainTitle="currentMainTitleData" :contentData="currentContentData" ></list-content>
           </div>
         </div>
         <content-footer></content-footer> 
    </div>
  </template>
  
  <script>
  import ListMenu from './components/list-menu.vue';
  import ListContent from './components/list-content.vue';
  import ContentFooter from '../home/component/content-footer.vue';
  export default {
      name:'HelpCenter',
      components:{
          ListMenu,
          ListContent,
          ContentFooter
      },
      data(){
        return{
          menuTitleAndContentData:[
            {
              mainTitle:"常见问题",
              mainLeftIcon:"/images/home/faq_question_icon.png",
              mainRightIcon:"/images/home/faq_right_icon.png",
              subItemData:[
                {
                  id:0,
                  subTitle:"如何购买？",
                  imgSrc:'https://newgamemarket.oss-cn-beijing.aliyuncs.com/tbh_pc_images/how_to_buy_steps.png'
                },
                {
                  id:1,
                  subTitle:'如何实名？',
                  imgSrc:'https://newgamemarket.oss-cn-beijing.aliyuncs.com/tbh_pc_images/real_name_authentication.png'
                }
              ]
           },
           {
            mainTitle:"QQ账号须知",
            mainLeftIcon:"/images/home/faq_qq_icon.png",
            mainRightIcon:"/images/home/faq_right_icon.png",
            subItemData:[
              {
                id:2,
                subTitle:"英雄联盟验号须知"
              },
              {
                id:3,
                subTitle:'王者荣耀验号须知'
              }
              ,
              {
                id:4,
                subTitle:'和平精英验号须知'
              }
            ]
           },
           {
            mainTitle:"微信账号须知",
            mainLeftIcon:"/images/home/faq_wx_icon.png",
            mainRightIcon:"/images/home/faq_right_icon.png",
            subItemData:[
              {
                id:5,
                subTitle:"英雄联盟验号须知"
              },
              {
                id:6,
                subTitle:'王者荣耀验号须知'
              },
              {
                id:7,
                subTitle:'和平精英验号须知'
              }
            ]
           }
          ],

          currentMainTitleData:undefined,
          currentContentData:undefined,

        }
      },
      methods:{
        currentClickMenuIndex(mianTitleIndex,subTitleIndex){
          //console.log('当前主菜单索引---'+mianTitleIndex);
          //console.log('当前点击的子菜单索引是---'+subTitleIndex)
          let tempArray = JSON.stringify(this.menuTitleAndContentData[mianTitleIndex].subItemData.filter(item=>
            item.id==subTitleIndex //寻找子菜单的id等于当前点击的子菜单索引：subTitleIndex
          ));
          this.currentContentData = JSON.parse(tempArray.substring(1,tempArray.length-1));
          this.currentMainTitleData = this.menuTitleAndContentData[mianTitleIndex].mainTitle;
        }
      },
      mounted(){
        window.scrollTo(0,0);
      },
      beforeDestroy(){
        //去掉左边菜单当前索引，因为它是全局的
        this.$store.commit('helpCenter/setCurrentMenuIndex',0)
      }
  }
  </script>
  
  <style lang="less" scoped>
  .container{
      background:#F5F5F5;
      width: calc(100vw);
      
      .content{
          margin: 0 auto;
          width: 1200px;
          display: flex;
          justify-content: space-between;
          .content-left{
            margin-top: 20px;
            width: 280px;
            min-height: 890px;
            border-radius: 6px;
            background-color: #FFFFFF;

          }
          .content-right{
            margin-top: 20px;
            padding: 20px;
            width: 900px;
            border-radius: 6px;
            background: #FFFFFF;
            
          }
      }
  }
  
  </style>